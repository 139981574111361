<template>
    <div class="my-4">
        <h5>
            Enrollment
            <router-link
                is="b-button"
                variant="link"
                class="ml-2"
                size="sm"
                :to="{ name: 'assign_relationship', query: { relationship: 'enrollment', target_id: class_id } }"
            >
                <font-awesome-icon
                    icon="user-plus"
                ></font-awesome-icon>
            </router-link>
            <b-button
                variant="link"
                class="ml-1"
                size="sm"
                @click="search = !search"
            >
                <font-awesome-icon
                    icon="search"
                ></font-awesome-icon>
            </b-button>
        </h5>
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <b-card>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                </b-card>
            </template>
            <b-collapse
                v-model="search"
            >
                    <b-card
                        class="mt-2"

                    >
                        <b-button-close
                            @click="search = false"
                            class="float-right"
                        ></b-button-close>
                        <h5>Filter</h5>
                        <b-input placeholder="Search information..." v-model="filter"></b-input>
                    </b-card>
            </b-collapse>
            <div
                class="scroll-x"
            >
                <b-table
                    class="mt-2 w-100"
                    :busy="loading"
                    striped
                    bordered
                    hover
                    :items="items"
                    :fields="fields"
                    selectable
                    select-mode="single"
                    @row-selected="onStudentSelected"
                    :filter="filter"
                    empty-text="No students are enrolled in this class yet."
                    empty-filtered-text="There are no results that match your criteria."
                    :show-empty="true"
                >
                    <template #cell(actions)="{ item }">
                        <div class="float-right">
                            <b-button
                                @click="unenroll(item)"
                                variant="link"
                            >
                                <font-awesome-icon
                                    icon="user-minus"
                                ></font-awesome-icon>
                            </b-button>
                        </div>
                    </template>
                    <template #empty-text>
                        There are no records to fetch.
                    </template>
                </b-table>
            </div>
        </b-skeleton-wrapper>
    </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
    props: ["class_id"],
    data() {
        return {
            loading: false,
            selectedClass: {},
            show: [ "active" ],
            search: false,
            filter: "",
            fields: [
                {
                    key         : "person.first_name",
                    label       : "First Name",
                    sortable    : true
                },
                {
                    key         : "person.last_name",
                    label       : "Last Name",
                    sortable    : true
                },
                {
                    key         : "registration_date",
                    sortable    : true,
                    formatter   : (value) => {
                        if (value == null) return "";
                        return this.$moment(value).format("LLLL");
                    }
                },
                {
                    key         : "actions",
                    sortable    : false
                }
            ],
            items: []
        };
    },
    mounted() {
        this.load();
    },
    methods: {
        onStudentSelected(student) {
            student._details = !student._details;
        },
        showActive(row) {
            return row.is_active == true;
        },
        unenroll(enrollment) {
            this.$api.post("/api/admin/unenroll-student", {
                id: enrollment.id
            }).then(({ data }) => {
                this.$bvToast.toast(data.message, {
                    title:   (data.success) ? "Success" : "Error",
                    variant: (data.success) ? "success" : "danger",
                    autoHideDelay: 5000,
                    appendToast: true
                });
                this.load();
            })
        },
        load() {
            this.loading = true;
            this.$api.get(
                "/api/admin/enrollment",
                { params: { class_id: this.class_id } }
            ).then((response) => {
                console.log("classes", response.data.enrollment);
                this.$set(this, "items", response.data.enrollment);
                this.loading = false;
            });
        }
    },
    computed: {
        ...mapState(["currentSemesterId"])
    }
  }
</script>
<style scoped>
    .scroll-x {
        max-width:  100%;
        overflow-x: scroll;
    }
</style>