<template>
    <div class="my-4">
        <h5>
            Teachers
            <router-link
                is="b-button"
                variant="link"
                class="ml-2"
                size="sm"
                :to="{ name: 'assign_relationship', query: { relationship: 'teaching-assignment', target_id: class_id } }"
            >
                <font-awesome-icon
                    icon="user-plus"
                ></font-awesome-icon>
            </router-link>
            <a
                v-if="teachers && teachers.length > 0"
                :href="mailAll"
                class="btn btn-link btn-sm ml-1"
            >
                <font-awesome-icon
                    class="mr-1"
                    icon="envelope"
                ></font-awesome-icon>
            </a>
        </h5>
        <b-list-group
            class="mt-3"
        >
            <b-list-group-item v-if="loading" class="text-center">
                <b-spinner variant="primary"></b-spinner>
            </b-list-group-item>
            <b-list-group-item v-if="teachers.length == 0">
                <font-awesome-icon icon="info-circle" class="mr-1"></font-awesome-icon>
                This class has not been assigned any teachers yet.
            </b-list-group-item>
            <b-list-group-item
                v-for="(teacher, idx) in teachers"
                v-bind:key="idx"
            >
                <div class="float-left">
                    {{ teacher.first_name }} {{ teacher.last_name }}<br/>
                    <span class="text-muted">{{ teacher.email }}</span>
                </div>
                <div class="float-right">
                    <b-button
                        variant="link"
                        class="mr-3"
                        @click="removeTeacher(teacher)"
                        size="sm"
                    >
                        <font-awesome-icon
                            icon="user-minus"
                        ></font-awesome-icon>
                    </b-button>
                    <a
                        class="btn btn-link btn-sm"
                        :href="'mailto:' + teacher.email"
                    >
                        <font-awesome-icon icon="envelope"></font-awesome-icon>
                    </a>
                </div>
            </b-list-group-item>
        </b-list-group>
    </div>
</template>
<script>
export default {
    props: ["class_id"],
    data() {
        return {
            loading  : false,
            teachers : []
        };
    },
    mounted() {
        this.getTeachers();
    },
    methods: {
        getTeachers() {
            this.loading = true;
            this.$api.get(
                "/api/admin/fetch-class-teachers",
                { params: {
                    id : this.class_id
                } }
            ).then(({ data }) => {
                this.teachers = data.records;
                this.loading  = false;
            });
        },
        removeTeacher(teacher) {
            this.$api.post(
                "/api/admin/remove-relationship",
                {
                    relationship : "teaching-assignment",
                    target_id    : this.class_id,
                    person_id    : teacher.id
                }
            ).then(({ data }) => {
                this.$bvToast.toast(data.message, {
                    title:   (data.success) ? "Success" : "Error",
                    variant: (data.success) ? "success" : "danger",
                    autoHideDelay: 5000,
                    appendToast: true
                });
                this.getTeachers();
            });
        }
    },
    computed: {
        mailAll() {
            return "mailto:" + this.teachers.map((teacher) => teacher.email).join(",");
        }
    }
}
</script>