<template>
    <div class="my-4">
        <h5>
            Enrollment Waiting List
            <router-link
                is="b-button"
                variant="link"
                class="ml-2"
                size="sm"
                :to="{ name: 'assign_relationship', query: { relationship: 'enrollment-waiting-list', target_id: class_id } }"
            >
                <font-awesome-icon
                    icon="user-plus"
                ></font-awesome-icon>
            </router-link>
            <b-button
                variant="link"
                class="ml-1"
                size="sm"
                @click="search = !search"
            >
                <font-awesome-icon
                    icon="search"
                ></font-awesome-icon>
            </b-button>
        </h5>
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <b-card>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                </b-card>
            </template>
            <b-collapse
                v-model="search"
            >
                    <b-card
                        class="mt-2"

                    >
                        <b-button-close
                            @click="search = false"
                            class="float-right"
                        ></b-button-close>
                        <h5>Filter</h5>
                        <b-input placeholder="Search information..." v-model="filter"></b-input>
                    </b-card>
            </b-collapse>
            <div
                class="scroll-x"
            >
                <b-table
                    class="mt-2 w-100"
                    :busy="loading"
                    striped
                    bordered
                    hover
                    :items="items"
                    :fields="fields"
                    selectable
                    select-mode="single"
                    @row-selected="onStudentSelected"
                    :filter="filter"
                    empty-text="There are no students waiting to enroll in this class."
                    empty-filtered-text="There are no results that match your criteria."
                    :show-empty="true"
                >
                    <template #cell(actions)="{ item }">
                        <div class="float-right">
                            <b-button
                                @click="enroll(item)"
                                variant="link"
                            >
                                <font-awesome-icon
                                    icon="user-plus"
                                ></font-awesome-icon>
                            </b-button>
                            <b-button
                                @click="remove(item)"
                                variant="link"
                            >
                                <font-awesome-icon
                                    icon="user-minus"
                                ></font-awesome-icon>
                            </b-button>
                        </div>
                    </template>
                    <template #row-details="{ toggleDetails }">    
                        <a class="close" @click="toggleDetails();">&times;</a>
                    </template>
                </b-table>
            </div>
        </b-skeleton-wrapper>
    </div>
</template>
<script>
import { mapState } from 'vuex';
//import MembershipFormInfo from './info/MembershipFormInfo.vue';

export default {
    //components: { MembershipFormInfo },
    props: ["class_id"],
    data() {
        return {
            loading: false,
            selectedClass: {},
            search: false,
            show: [ "active" ],
            filter: "",
            fields: [
                {
                    key         : "person.first_name",
                    label       : "First Name",
                    sortable    : true
                },
                {
                    key         : "person.last_name",
                    label       : "Last Name",
                    sortable    : true
                },
                {
                    key         : "waiting_date",
                    sortable    : true,
                    formatter   : (value) => {
                        if (value == null) return "";
                        return this.$moment(value).format("LLLL");
                    }
                },
                {
                    key         : "actions",
                    sortable    : false
                }
            ],
            items: []
        };
    },
    mounted() {
        this.loadWaitingList();
    },
    methods: {
        onStudentSelected(student) {
            student._details = !student._details;
        },
        showActive(row) {
            return row.is_active == true;
        },
        remove(waitingListItem) {
            this.$api.post("/api/admin/remove-from-waiting-list", {
                id: waitingListItem.id
            }).then((response) => {
                var data = response.data;
                this.$bvToast.toast(data.message, {
                    title:   (data.success) ? "Success" : "Error",
                    variant: (data.success) ? "success" : "danger",
                    autoHideDelay: 5000,
                    appendToast: true
                });
                this.loadWaitingList();
            });
        },
        enroll(waitingListItem) {
            console.log(waitingListItem);
            this.$api.post("/api/admin/enroll-student", {
                class_id   : this.class_id,
                student_id : waitingListItem.student_id
            }).then(({ data }) => {
                this.$bvToast.toast(data.message, {
                    title:   (data.success) ? "Success" : "Error",
                    variant: (data.success) ? "success" : "danger",
                    autoHideDelay: 5000,
                    appendToast: true
                });
                this.$emit("enroll");
                this.loadWaitingList();
            });
        },
        loadWaitingList() {
            this.loading = true;
            this.$api.get(
                "/api/admin/enrollment-waiting-list",
                { params: { class_id: this.class_id } }
            ).then(({ data }) => {
                this.$set(this, "items", data.records);
                this.loading = false;
            });
        }
    },
    computed: {
        ...mapState(["currentSemesterId"])
    }
  }
</script>
<style scoped>
    .scroll-x {
        max-width:  100%;
        overflow-x: scroll;
    }
</style>